<template>
  <v-container>
    <v-row no-gutters v-if="category">
      <v-col cols="12">
        <CategoryTitle :category="category" />
      </v-col>
      <v-col cols="12" v-if="cuFormContent && config">
        <FormDetail :cuFormContent="cuFormContent" :config="config" />
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss"></style>
<script>
//import productMixin from "~/mixins/product";
import category from "~/mixins/category";
import FormDetail from "../categoryBlock/FormDetail.vue";
import { mapGetters } from "vuex";
import ProductService from "~/service/productService";
import CategoryTitle from "@/components/category/CategoryTitle";
//import cuFormService from "@/commons/service/cuFormService";

export default {
  name: "ProductRequestInfo",
  mixins: [category],
  components: { CategoryTitle, FormDetail },

  data() {
    return {
      product: null,
      config: null,
      cuFormContent: null
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      user: "cart/getUser"
    })
  },
  methods: {
    async getProduct() {
      let result = await ProductService.getProductsByIds([
        this.$route.query.productId
      ]);
      this.product = result.products[0];
    }
  },
  async created() {
    await this.getProduct();
    this.config = {
      metaData: {
        categoryblocktype_FormDetail: {
          FORM_ID: this.isAuthenticated && this.user ? 2 : 1
        }
      }
    };
    this.cuFormContent = {
      group1: {
        schema: {
          Product_Id: { value: this.$route.query.productId, required: true },
          Product_Description: {
            value: this?.product.description || "",
            required: true
          }
        }
      }
    };
  }
};
</script>
